@import url(https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/* hide scrollbar but allow scrolling */

body {
  font-family: 'Assistant', sans-serif;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 
  color: #000;
  height: 100%;
  font-size: 14px;
}
.text-right {
  text-align: right;
}
body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.mt-10 { margin-top: 10px; }
.mt-20 { margin-top: 20px !important; }
a {
  color: #ec0c5d;
  text-decoration: none;
}
.text-left { text-align: left !important; }
.text-right { text-align: right !important; }
.forgot-password {
  color: #ec0c5d;
}

.forgot-password a {
  color: #ec0c5d !important;
  text-decoration: none !important;
}

.login {
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  height: 100vh;
  background-color: #fff;
  font-size: 14px;
}

input[type="text"],
input[type="password"] {
  height: 25px;
  background: #edeef2;
  outline: none;
  border: 0px !important;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 325px;
  border-radius: 10px;
  font-size: 14px;
  color: #000;
}

::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(139, 149, 158);
  opacity: 1; /* Firefox */
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(139, 149, 158);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgb(139, 149, 158);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: rgb(139, 149, 158);
}

.loginBtn {
  margin-top: 10px;
  cursor: pointer;
  background: #ec0c5d;
  border: 1px solid #ec0c5d;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 14px;
  width: 150px;
  margin-top: 30px;
}

.loginBtn:hover {
  background: #fff;
  border-color: #ec0c5d;
  color: #ec0c5d;
}

.mt-2 {
  margin-top: 2rem;
}

.button-container {
  display: flex;
  justify-content: center;
}

.login-form {
  background-color: white;
  padding: 2rem;
}

.list-container {
  display: flex;
}

.error {
  color: red;
  font-size: 14px;
}

.title {
  font-size: 25px;
  margin-bottom: 20px;
}

.input-container {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  margin: 10px;
}

.extra-buttons {
  display: flex;
  margin-left: 8px;
}

.extra-buttons > div {
  background-color: #fff;
  width: 50%;
  margin: 10px;
  text-align: center;
  font-size: 14px;
}

.login .logo {
  width: 180px;
  margin: 0px auto;
  display: block;
  margin-bottom: 60px;
}

.error-container .error {
  text-align: center;
  margin-top: 15px;
}

.desktop-container {
  display: flex;
  flex-direction: row;
}

.desktop-left {
  min-width: 400px;
  width: 25%;
  height: 100vh;
  margin: 5px;
  padding: 5px;
  border-right: 1px solid #eee;
}

.desktop-right {
  width: 75%;
  min-width: 750px;
  margin: 5px;
  padding: 5px;
}
.wrapper-right {
  padding-left: 25px;
  padding-right: 25px;
}

.desktop-container .row {
  position: relative;
  display: block;
  width: 100%;
}

.desktop-container .logo {
  text-align: left;
  display: block;
  width: 100px;
  margin-bottom: 35px;
  margin-top: 15px;
}

.refreshIcon {
  text-align: left;
  margin-top: 0px;
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
}

.refresh {
  text-align: left;
  display: block;
  width: 10px;
  margin-bottom: 10px;
  margin-top: 15px;
}

.refreshIcon .text {
  margin-top: 5px;
  margin-left: 10px;
  color: #000;
  font-size: 0.8rem;
}

.desktop-container .timer-clock {
  background: #fff;
  width: 75%;
  text-align: center;
  color: #ec0c5d;
  margin: 0px auto;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: normal;
  font-size: 1.5em;
  border-radius: 15px;
  border: 1px solid #ec0c5d;
}

.inline-hidden {
  display: inline-block;
  width: 45%;
}

.inline-completed {
  display: inline-block;
  width: 50%;
}

.version {
  color: #000;
  font-size: 0.8rem;
}

.timer-clock .icon {
  
}
.infobubble {
  background-color: white;
  display: inline-block;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  padding: 0px;
  font-size: 20px;
  font-weight: bold;
  margin-left: 5px;
  box-sizing: border-box;
  border: 1px solid #ec0c5d;
  color: #ec0c5d;
  cursor: pointer;
}
.task-dropdown {
  right: 10px;
  width: auto;
  text-align: left;
  font-weight: normal;
}
.bubble-dropdown {
  right: 10px;
  width: 200px;
  text-align: left;
}
.bubble-dropdown .titlebubble {
  color: #ec0c5d;
}
.bubble-dropdown p {
  margin: 0px;
}
.tasks-lister .table-date {
  padding-left: 15px;
}
.tasks-lister .table-status {
  padding-left: 0px;
}
.tasks-lister .table-due {
  padding-right: 2px;
}
.tasks-lister .table-dots {
  padding-right: 13px;
}
.project-task-showcase {
  text-align: center;
  margin: 0px auto;
}
.imgAvatar {
  width: 155px;
  margin-top: 30px;
}

.play {
  width: 45px;
  margin: 0px auto;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.taskTableDetails.active .left-task {
  width: calc(75% - 25px);
}

.cursor-pointer {
  cursor: pointer;
}

.play-sm {
  width: 20px;
  margin: 0px auto;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 0px;
}

.play-sm-client {
  width: 20px;
  margin: 0px auto;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0px;
}
.clientNameTrigger {
  width: calc(100% - 45px);
  display: inline-block;
}

.project-task-showcase-search {
  text-align: center;
  margin: 0px auto;
  width: 100%;
  max-width: 100%;
}

.desktop-container .search-bar {
  background: #edeef2;
  color: #000;
  margin: 0px auto;
  display: block;
  padding-right: 45px;

}

.task-name {
  margin-top: 8px;
  margin-bottom: 0px;
}

.client-name {
  margin-bottom: 0px;
  font-weight: normal;
}

.list-clients {
  margin-top: 5px;
  background: #edeef2;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 0px;
  width: 100%;
}
.clientsName {
  padding-left: 15px;
  font-weight: bold;
}

ul.list {
  padding-left: 0px;
  margin-top: 0px;
}

.list li {
  padding-left: 15px;
  list-style-type: none;
  margin-left: 0px;
  border-bottom: 1px solid #ec0c5d;
  padding-top: 15px;
  padding-bottom: 15px;
}

.list li.active {
  display: flex;
  align-items: center;
}

.list li.activeClick {
  color: #000;
  font-weight: bold;
}

.list li:first-child {
  
}

.flexcroll{
  scrollbar-face-color: #bcbec0;
  scrollbar-shadow-color: #FFFFFF;
  scrollbar-highlight-color: #FFFFFF;
  scrollbar-3dlight-color: #FFFFFF;
  scrollbar-darkshadow-color: #FFFFFF;
  scrollbar-track-color: #FFFFFF;
  scrollbar-arrow-color: #FFFFFF;
}

.flexcroll-mini{
  max-height: 375px;
  overflow-y: auto;
}

.chart-container {
  max-height: 45vh;
  overflow-y: auto;
}

/* Let's get this party started */
.flexcroll::-webkit-scrollbar {
  width: 12px;
}

/* Track */
.flexcroll::-webkit-scrollbar-track {
  background: #eeefef;
  border-radius: 10px;
}
ul.clients-display li {
  padding-top: 10px;
  border: none;
}

/* Handle */
.flexcroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #bcbec0; 
}

.user-avatar {
  text-align: right;
  width: 50px;
}

.right-task-intro {
  text-align: left;
}

.right-task-entry {
  text-align: left;
  margin-top: 45px;
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
}

.right-task-entry .left {
  width: 45%;
}

.right-task-entry .right {
  width: 55%;
}

.tasks-lister {
  text-align: left;
  margin-top: 45px;
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  margin-bottom: 15px;
  font-weight: bold;
  color: #ec0c5d !important;
}

.tasks-lister .left {
  width: calc(75% - 15px);
  padding-left: 10px;
}

.tasks-lister .right {
  width: 25%;
}

.flexcroll-mini2{
  max-height: 32vh;
  overflow-y: auto;
}

.desktop-container .search-bar-tasks {
  background: #edeef2;
  color: #000;
  width: 100%;
}

.search-client {
  display: none !important;
}

.dots {
  color: #ec0c5d !important;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 0;
}

li.taskTableDetails {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
}
.left-task {
    width: 75%;
}
.right-task {
  width: 25%;
}
.smallbtnblock {
  width: 20px;
  padding-right: 0px;
}

.smallbtnblockClient {
  width: auto;
  padding-right: 15px;
  display: inline-block;
}

 input[type="checkbox"] {
	 position: relative;
	 width: 1.5em;
	 height: 1.5em;
	 color: #363839;
	 border: 1px solid #bdc1c6;
	 border-radius: 4px;
	 -webkit-appearance: none;
	         appearance: none;
	 outline: 0;
	 cursor: pointer;
	 transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
}
 input[type="checkbox"]::before {
	 position: absolute;
	 content: '';
	 display: block;
   top: 0px;
   left: 6px;
   width: 5px;
	 height: 14px;
	 border-style: solid;
	 border-color: #fff;
	 border-width: 0 2px 2px 0;
	 -webkit-transform: rotate(45deg);
	         transform: rotate(45deg);
	 opacity: 0;
}
 input[type="checkbox"]:checked {
	 color: #fff;
	 border-color: #ec0c5d;
	 background: #ec0c5d;
}
 input[type="checkbox"]:checked::before {
	 opacity: 1;
}
 label.checkbox {
	 position: absolute;
	 cursor: pointer;
	 padding: 0 0.25em 0;
	 -webkit-user-select: none;
	         user-select: none;
   margin-top: 5px !important;
}
 label.checkbox::before {
	 position: absolute;
	 content: attr(data-content);
	 color: #9c9e9f;
	 -webkit-clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
	         clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
	 text-decoration: line-through;
	 text-decoration-thickness: 3px;
	 -webkit-text-decoration-color: #363839;
	         text-decoration-color: #363839;
	 transition: -webkit-clip-path 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	 transition: clip-path 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	 transition: clip-path 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94), -webkit-clip-path 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.loaderFullScreen {
  background: #fff;
  position: absolute;
  z-index: 999999;
  text-align: center;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
}

.loaderFullScreen .absolute-spinner {
  top: 50.5%;
  left: 49.5%;
  position: absolute;
  font-size: 7px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite;
}

body {
  overflow: hidden;
}

.dropdown {
  width: 120px;
  margin-left: auto; 
  margin-right: 0;
}

.dropdown-header {
  padding: 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 16px;
}

.dropdown-body {
  padding: 5px;
  display: none;
  position: absolute;
  right: 0;
  padding-right:15px;
}

.dropdown-body.open {
  display: block;
  border-radius: 10px;
  box-shadow: 0 10px 25px rgba(0,0,0,.1);
  background-color: white;
  z-index: 999999;

}

.dropdown-item {
  padding: 10px;
}

.dropdown-item:hover {
  cursor: pointer;
}

.dropdown-item-dot {
  opacity: 0;
  color: #91A5BE;
  transition: all .2s ease-in-out;
}

.dropdown-item-dot.selected {
  opacity: 1;
}

.icon {
  font-size: 13px;
  color: #91A5BE;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition: all .2s ease-in-out;
}

.icon.open {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.flex-display {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
}

.leftBottomDisplay {
  width: 75%;
}
.leftBottomDisplay .block {
  display: inline-block;
}
.due-mrg {
  margin-left: 45px;
}
.rightBottomDisplay {
  width: 25%;
  text-align: right;
}

.rightBottomDisplayo {
  width: 12.5%;
  color: #ec0c5d;
}

.lastActivity {
  color: #ccc;
}

.selectAccount {
  height: 25px;
  background: #edeef2;
  outline: none;
  border: 0px !important;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  font-size: 14px;
  color: #000;
  width: 335px;
  height: 35px;
  margin-left: 15px;
}

.selectFilterOption {
  height: 25px;
  background: #edeef2;
  outline: none;
  border: 0px !important;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  font-size: 14px;
  color: rgb(139, 149, 158);
  width: 100%;
  height: 35px;
  margin-left: 15px;
}

.addTask-row {
  margin-top: 25px;
  text-align: left;
  margin-top: 45px;
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
}

.addTask-row .bar {
  background: #fff;
  border: 2px solid #eee !important;
  color: #000;
}

.addFieldInput {
  width: calc(100% - 73px) !important;
}

.addTaskBtn {
  margin-top: 10px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #ec0c5d;
  color: #ec0c5d;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 14px;
  width: 15px;
  font-size: 21px;
  margin-left: 15px;
  font-weight: bold;
  margin-top: 0;
  display: inline-block;
  text-align: center;
}

.addTask-row .left {
    width: 45%;
}
.addTask-row .addbox {
  width: calc(25% + 40px);
  padding-right: 25px;
  padding-left: 0px;
}
.addTask-row .searchbox {
  width: calc(25% - 12px);
  padding-right: 5px;
  padding-left: 5px;
}
.addTask-row .filterbox {
  width: calc(25% - 12px);
  padding-right: 5px;
  padding-left: 5px;
}
.addTask-row .statusbox {
  width: calc(25% - 12px);
  padding-right: 5px;
  padding-left: 5px;
}
.addTask-row .right {
  width: 55%;
}

.table-task {
  width: 30%;
  padding-left: 10px;
}
.table-date {
  width: 20%;
}
.table-status {
  width: 20%;
}
.table-due {
  width: 20%;
}
.table-dots {
  width: 10%;
}
.spinnerLoad {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 300px;
}
.spaceblock {
 width: 20px;
}
.plan-reached {
  color: red;
  font-size: 14px;
}
li.taskTableDetails {
  padding-left:0px;
}
li.taskTableDetails.active {
  margin-left: 0px;
}
.hide-task {
  cursor: pointer;
}

.table-dots .dots {
  cursor: pointer;
}

button:disabled,
button[disabled]{
  opacity: 0.7;
}

.fixed-bottom-task {
  padding-left: 0px;
  padding-right: 6%;
}
